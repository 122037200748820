import { modalAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

// Modal.height - ModalHeader.height - ModalFooter.height
const maxBodyHeight = `${500 - 67 - 61}px`;

export const modalStyle = defineMultiStyleConfig({
  baseStyle: {
    dialogContainer: {
      alignItems: 'center',
      shadow: '0 2px 10px #0000000F',
    },
    header: {
      paddingBottom: '12px',
      h: '36px',
      fontSize: 'xl',
    },
    body: {
      padding: '0 24px 32px',
      fontSize: '14px',
      overflowY: 'auto',
    },
    footer: {
      padding: '12px 24px',
      borderTopWidth: '1px',
      borderTopStyle: 'solid',
      borderTopColor: 'gray.100',
      gap: '8px',
    },
    closeButton: {
      top: '16px',
      right: '16px',
      color: 'blue.500',
    },
  },
  variants: {
    fixedHeight: {
      body: {
        h: maxBodyHeight,
        minH: maxBodyHeight,
        maxH: maxBodyHeight,
      },
    },
    maxHeight: {
      body: {
        maxH: maxBodyHeight,
      },
    },
  },
  sizes: {
    sm: {
      dialog: {
        width: '300px',
        minWidth: '300px',
        maxWidth: '300px',
      },
    },
    md: {
      dialog: {
        width: '500px',
        minWidth: '500px',
        maxWidth: '500px',
      },
    },
    lg: {
      dialog: {
        width: '700px',
        minWidth: '700px',
        maxWidth: '700px',
      },
    },
    xl: {
      dialog: {
        width: '900px',
        minWidth: '900px',
        maxWidth: '900px',
      },
    },
  },
  defaultProps: {
    size: 'md',
  },
});
