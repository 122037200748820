import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  inputAnatomy.keys,
);

export const inputStyle = defineMultiStyleConfig({
  baseStyle: {
    field: {
      fontSize: 'md',
      px: 3,
    },
  },
  defaultProps: {
    size: 'md',
    variant: 'outline',
  },
  variants: {
    outline: {
      group: {},
      addon: {},
      element: {
        px: 0,
        w: 9,
      },
      field: {
        bgColor: 'white',
        borderColor: 'gray.200',
        color: 'gray.500',
        outline: 'none',
        _hover: {
          borderColor: 'gray.200',
        },
        _placeholder: {
          color: 'gray.300',
        },
        _disabled: {
          bgColor: 'gray.50',
          color: 'gray.300',
          opacity: '1',
        },
        _invalid: {
          borderColor: 'red.500',
          shadow: 'none',
        },
        _focus: {
          borderColor: 'blue.500',
        },
        _focusVisible: {
          shadow: 'none',
        },
      },
    },
  },
  sizes: {
    lg: {
      field: {
        h: '40px',
      },
      element: {
        h: '40px',
      },
      addon: {
        h: '40px',
      },
    },
    md: {
      field: {
        h: '36px',
        lineHeight: '36px',
      },
      element: {
        h: '36px',
      },
    },
    sm: {
      field: {
        h: '30px',
      },
    },
  },
});
