import {
  Modal,
  ModalContent,
  ModalOverlay,
  Spinner,
  Text,
} from '@chakra-ui/react';

type LoaderProps = {
  isOpen: boolean;
  onClose?: () => void;
};

export function Loader({ isOpen, onClose = () => {} }: LoaderProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay backgroundColor="rgba(34,34,34,.25)" />
      <ModalContent
        background="transparent"
        boxShadow="none"
        alignItems="center"
        justifyContent="center"
      >
        <Spinner
          color="blue.500"
          emptyColor="white"
          thickness="3px"
          speed="0.65s"
          h={54}
          w={54}
        />

        <Text size="md" color="gray.500" fontWeight={300} mt={3}>
          読み込み中…
        </Text>
      </ModalContent>
    </Modal>
  );
}
