export const __DEV__ = process.env.NEXT_PUBLIC_DEBUG === 'true';
export const env = {
  api: {
    BASE_URL: process.env.NEXT_PUBLIC_API_URL || '',
    TOKEN_NAME: 'meo_session',
  },
  localStorage: {
    PERMISSION_KEY: 'casl',
    OPTION_KEY: 'option',
  },
  LOCALE: 'ja_JP',
  ALLOW_ROBOTS: process.env.NEXT_PUBLIC_ALLOW_ROBOTS === 'true',
  facebook: {
    APP_ID: process.env.NEXT_PUBLIC_FACEBOOK_APP_ID || '',
    API_VERSION: process.env.NEXT_PUBLIC_META_GRAPH_API_VERSION || 'v18.0',
    SDK_ID: 'facebook-js-sdk',
    scopes: [
      'business_management',
      'pages_show_list',
      'pages_read_engagement',
      'pages_read_user_content',
      'pages_manage_posts',
      'pages_manage_metadata',
    ].join(','),
  },
  instagram: {
    scopes: [
      'business_management',
      'instagram_basic',
      'instagram_content_publish',
      'pages_show_list',
      'pages_read_engagement',
      'pages_read_user_content',
      'pages_manage_posts',
      'pages_manage_metadata',
    ].join(','),
  },
};
